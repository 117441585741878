import {useEffect, useState} from 'react'
import {initialQueryState} from '../../../../../../_metronic/helpers'
import {City, useAuth} from '../../../../../modules/auth'
import {getDistrictByCityId} from '../../../../diachi/diachi-list/core/_requests'
import {District} from '../../../../MAP_New2/core/_models'
import {getProvinces} from '../../../../MAP_New2/core/_requests'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {UserGroupsListToolbar} from './UserGroupListToolbar'
import {Select} from 'antd'
import compareLabelMatch from '../../../../../../untils/compare-label-match'
import '../../../../../../_metronic/assets/sass/StyleSelect.scss'

const UserGroupsListHeader = () => {
  const {auth} = useAuth()
  const {state, updateState} = useQueryRequest()
  const [lstCity, setLstCity] = useState<Array<City>>([])
  const [cityIdSelected, setCityIdSelected] = useState<string>('')
  const [lstDistrict, setLstDistrict] = useState<Array<District>>([])
  const [districtIdSelected, setDistrictIdSelected] = useState<string>('')

  useEffect(() => {
    getProvinces(auth?.configs).then((data) => {
      setLstCity([{CityName: 'Tất cả', CityId: ''}, ...data])
      if (data.length > 0) {
        setCityIdSelected(
          (localStorage.getItem('diachi') &&
            JSON.parse(localStorage.getItem('diachi') ?? '')?.tinh).trim() ||
            data[0].CityId ||
            ''
        )
        getDistrictByCityId(
          auth?.configs,
          (localStorage.getItem('diachi') &&
            JSON.parse(localStorage.getItem('diachi') ?? '')?.tinh) ||
            data[0]?.CityId ||
            ''
        ).then((res) => {
          setLstDistrict(res)
        })
      }
    })
  }, [])

  const changeCity = (cId: string) => {
    setCityIdSelected(cId)
    setLstDistrict([])
    getDistrictByCityId(auth?.configs, cId).then((res) => {
      setLstDistrict(res)
    })
    setDistrictIdSelected('')
  }

  useEffect(() => {
    if (cityIdSelected.length >= 0) {
      let tmp: any = {
        ...((state.filter as object) || {
          Location: {
            CityId: cityIdSelected,
            DistrictId: '',
          },
          FromTime: null,
          ToTime: null,
          DeviceGroupName: '',
          Info: '',
          CreateByUser: '',
          ImeiMaster: '',
          NguonID: '',
        }),
      }
      tmp['Location'] = {
        CityId: cityIdSelected,
        DistrictId: districtIdSelected,
      }
      updateState({
        search: Math.random().toString(),
        filter: tmp,
        ...initialQueryState,
      })
    }
    setTimeout(() => {
      if (cityIdSelected || districtIdSelected) {
        localStorage.setItem(
          'diachi',
          JSON.stringify({tinh: cityIdSelected, huyen: districtIdSelected, xa: ''})
        )
      }
    }, 500)
  }, [cityIdSelected, districtIdSelected])

  return (
    <div className='card-header border-0 pt-6 nguoi-dung-body'>
      <div className='card-title'>
        {/* begin::Search */}
        {/* <div className='d-flex align-items-center position-relative'>Quản lý đài truyền thanh</div> */}
        <div className='div-select-city'>
          <label className='fw-bold fs-6 mb-2'>Tỉnh:</label>
          <Select
            style={{width: '200px'}}
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            data-kt-user-table-filter='two-step'
            data-hide-search='true'
            value={cityIdSelected}
            onChange={(value) => changeCity(value)}
            options={lstCity.map((item) => ({label: item.CityName, value: item.CityId}))}
            showSearch
            filterOption={compareLabelMatch}
          />
        </div>
        <div className='div-select-city'>
          <label className='fw-bold fs-6 mb-2'>Huyện:</label>
          <Select
            style={{width: '200px'}}
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            data-kt-user-table-filter='two-step'
            data-hide-search='true'
            value={districtIdSelected}
            onChange={(value) => setDistrictIdSelected(value)}
            options={[
              {
                label: 'Tất cả',
                value: '',
              },
              ...lstDistrict?.map((item) => ({
                label: item.DistrictName,
                value: item.DistrictId,
              })),
            ]}
            showSearch
            filterOption={compareLabelMatch}
          />
        </div>
        {/* end::Search */}
      </div>
      {/* <UsersListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>{<UserGroupsListToolbar />}</div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UserGroupsListHeader}
