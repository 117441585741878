//import React, {useState} from 'react'
import axios, {AxiosResponse} from 'axios'
import {DynamicConfig} from '../../../../../configuration/config'
//import { Query } from 'react-query'
import {QueryState, Response} from '../../../../../_metronic/helpers'
import {UserModel} from '../../../../modules/auth'
import {City, District, Ward} from '../../../MAP_New2/core/_models'
import {UsersQueryResponse} from '../../../nguoidung/nguoidung-list/core/_modelsDeviceGroup'
import {ManufacturesQueryResponse} from '../../../nhacungcap/nhacungcap-list/core/_models'
import {DeviceInfoQueryResponse, DeviceRequest} from './_models'

// const API_URL = process.env.REACT_APP_API_URL
// const URL = `${API_URL}/DeviceInfo`
const Manufacture = '/Manufacture'
const deviceGroups = '/deviceGroups'
const deviceInfo = '/DeviceInfo'
const location = '/location'
// const DEVICESTATEREPORT = '/deviceStateReport'
const deviceInfo_ = '/DeviceInfo'

const getAllDeviceInfo = (
  configs: DynamicConfig,
  state: QueryState,
  currentUser?: UserModel
): Promise<DeviceInfoQueryResponse> => {
  return axios
    .post(
      `${configs.apiUrl}${deviceInfo}/get-listDeviceInfoByFilter`,
      {
        Info: state.search2 || '',
        State: parseInt(state.tmp || '') ?? null,
        GroupIds: currentUser?.DeviceGroupIds,
      },
      {
        headers: {
          currentPage: state.currentPage,
          pageSize: state.pageSize,
        },
      }
    )
    .then((d: AxiosResponse<DeviceInfoQueryResponse>) => d.data)
}

const getAllDeviceInfo2 = (
  configs: DynamicConfig,
  state: QueryState,
  currentUser?: UserModel,
  lstImei?: Array<string>
): Promise<DeviceInfoQueryResponse> => {
  return axios
    .post(
      `${configs.apiUrl}${deviceInfo}/get-listDeviceInfoByFilter`,
      {
        Info: state.search2 || '',
        State: parseInt(state.tmp || '') ?? null,
        GroupIds: currentUser?.DeviceGroupIds,
        ListImei: lstImei,
      },
      {
        headers: {
          currentPage: state.currentPage,
          pageSize: state.pageSize,
        },
      }
    )
    .then((d: AxiosResponse<DeviceInfoQueryResponse>) => d.data)
}

const getlistDeviceInfoByFilter = (
  configs: DynamicConfig,
  state: QueryState
): Promise<DeviceInfoQueryResponse> | undefined => {
  if (state.filter === undefined) return undefined
  return axios
    .post(`${configs.apiUrl}${deviceInfo}/get-listDeviceInfoByFilter`, state.filter, {
      headers: {
        currentPage: state.currentPage,
        pageSize: state.pageSize,
      },
    })
    .then((d: AxiosResponse<DeviceInfoQueryResponse>) => d.data)
}

const getDeviceById = (configs: DynamicConfig, id: string): Promise<DeviceRequest> => {
  return axios
    .post(`${configs.apiUrl}${deviceInfo}/get-deviceById`, {Id: id})
    .then((response: AxiosResponse<DeviceRequest>) => response.data)
}

const controlDeviceInfo = (
  configs: DynamicConfig,
  Imei: string,
  Control: Number,
  Value: string
): Promise<any> => {
  return axios
    .post(`${configs.apiUrl}${deviceInfo}/control-StateDeviceInfo`, {
      Imei: Imei,
      Control: Control,
      Value: Value,
    })
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getTypeConfig = (configs: DynamicConfig): Promise<any> => {
  return axios
    .get(`${configs.apiUrl}${deviceInfo}/get-deviceConfigs`, {})
    .then((response: Response<any>) => response.data)
}

const checkDeleteDevice = (configs: DynamicConfig, Id: string, onError: any): Promise<void> => {
  return axios
    .put(`${configs.apiUrl}${deviceInfo}/check-delete-deviceInfo`, {Id: Id})
    .then(() => {})
    .catch((e) => onError(Id, e.response.data))
}

const deleteDeviceInfo = (configs: DynamicConfig, id: string): Promise<void> => {
  return axios.put(`${configs.apiUrl}${deviceInfo}/delete-deviceInfo`, {Id: id}).then(() => {})
}

const ExportDeviceInfor = (configs: DynamicConfig, state: QueryState): Promise<Blob> => {
  return axios
    .post(`${configs.apiUrl}${deviceInfo}/export-listDeviceInfo`, state, {responseType: 'blob'})
    .then((d: AxiosResponse<Blob>) => d.data)
}

const ExportDeviceInfor2 = (configs: DynamicConfig, state: QueryState): Promise<Blob> => {
  return axios
    .post(`${configs.apiUrl}${deviceInfo}/export-listDeviceInfo`, state.filter, {
      responseType: 'blob',
    })
    .then((d: AxiosResponse<Blob>) => d.data)
}

const getListManufacture = (configs: DynamicConfig): Promise<ManufacturesQueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${Manufacture}/get-manufactures`, {
      headers: {
        currentPage: 1,
        pageSize: 1000,
      },
    })
    .then((d: AxiosResponse<ManufacturesQueryResponse>) => d.data)
}

const getListDeviceGroupByUsername = (
  configs: DynamicConfig,
  username: string
): Promise<UsersQueryResponse> => {
  return axios
    .post(
      `${configs.apiUrl}${deviceGroups}/get-deviceGroupByUser`,
      {Username: username},
      {
        headers: {
          currentPage: 1,
          pageSize: 99999,
        },
      }
    )
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}
const getProvinces = (configs: DynamicConfig): Promise<Array<City>> => {
  return axios
    .get(`${configs.apiUrl}${location}/get-listCityByUser`)
    .then((response: AxiosResponse<Array<City>>) => response.data)
}

const getListDistrictByCityId = (configs: DynamicConfig, id: string): Promise<Array<District>> => {
  return axios
    .post(`${configs.apiUrl}${location}/get-districtByCityId`, {
      CityId: id,
    })
    .then((response: AxiosResponse<Array<District>>) => response.data)
}

const getListWardByDistrictId = (
  configs: DynamicConfig,
  cityId: string,
  districtId: string
): Promise<Array<Ward>> => {
  return axios
    .post(`${configs.apiUrl}${location}/get-wardByDistrictId`, {
      CityId: cityId,
      DistrictId: districtId,
    })
    .then((response: AxiosResponse<Array<Ward>>) => response.data)
}

const createDeviceInfo = (
  configs: DynamicConfig,
  deviceInfo: DeviceRequest
): Promise<DeviceRequest | undefined> => {
  return axios
    .post(`${configs.apiUrl}${deviceInfo_}/create-DeviceInfo`, {
      Imei: deviceInfo.Imei,
      GroupID: deviceInfo.GroupID,
      Address: deviceInfo.Address,
      Info: deviceInfo.Info,
      DeviceType: deviceInfo.DeviceType,
      Longitude: deviceInfo.Longitude,
      Latitude: deviceInfo.Latitude,
      ManufactureId: deviceInfo.ManufactureId,
      InitTime: deviceInfo.InitTime,
      WarrantyTime: deviceInfo.WarrantyTime,
      ServiceType: deviceInfo.ServiceType,
      CustomerPhone: deviceInfo.CustomerPhone,
    })
    .then((response: AxiosResponse<Response<DeviceRequest>>) => response.data)
    .then((response: Response<DeviceRequest>) => response.data)
}
const updateDeviceInfo = (
  configs: DynamicConfig,
  deviceInfo: DeviceRequest
): Promise<DeviceRequest | undefined> => {
  return axios
    .post(`${configs.apiUrl}${deviceInfo_}/update-DeviceInfo`, {
      Id: deviceInfo.Id,
      Imei: deviceInfo.Imei,
      GroupID: deviceInfo.GroupID,
      Address: deviceInfo.Address,
      Info: deviceInfo.Info,
      DeviceType: deviceInfo.DeviceType,
      Longitude: deviceInfo.Longitude,
      Latitude: deviceInfo.Latitude,
      ManufactureId: deviceInfo.ManufactureId,
      InitTime: deviceInfo.InitTime,
      WarrantyTime: deviceInfo.WarrantyTime,
      ServiceType: deviceInfo.ServiceType,
      CustomerPhone: deviceInfo.CustomerPhone,
      Speakers: deviceInfo.Speakers,
    })
    .then((response: AxiosResponse<Response<DeviceRequest>>) => response.data)
    .then((response: Response<DeviceRequest>) => response.data)
}

export {
  checkDeleteDevice,
  controlDeviceInfo,
  createDeviceInfo,
  deleteDeviceInfo,
  ExportDeviceInfor,
  ExportDeviceInfor2,
  getAllDeviceInfo,
  getAllDeviceInfo2,
  getDeviceById,
  getListDeviceGroupByUsername,
  getlistDeviceInfoByFilter,
  getListDistrictByCityId,
  getListManufacture,
  getListWardByDistrictId,
  getProvinces,
  getTypeConfig,
  updateDeviceInfo,
}
