import {AudioOutlined, SettingOutlined} from '@ant-design/icons'
import {Modal} from 'antd'
import Countdown from 'antd/lib/statistic/Countdown'
import clsx from 'clsx'
import {FC, useEffect, useRef, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {useLocation} from 'react-router-dom'
import Swal from 'sweetalert2'
import {Role, useAuth} from '../../../../app/modules/auth'
import {useQueryResponse} from '../../../../app/pages/MAP_New2/core/QueryResponseProvider'
import {callEmergency} from '../../../../app/pages/phattructiep/streaming-list/core/_requests'
import generateCallId from '../../../../app/pages/phattructiep/streaming-list/core/generate-call-id'
import {PingState} from '../../../../app/pages/phattructiep/streaming-rtc-list/core/_models'
import {
  pingStreamState,
  stopStreaming,
} from '../../../../app/pages/phattructiep/streaming-rtc-list/core/_requests'
import {QUERIES, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks} from '../../../partials'
import {Loading} from '../loading/Loading'
import BitrateModal from './modal/BitrateModal'
import CallSOSModal from './modal/CallSOSModal'

const NUM_BARS = 150 // Số lượng cột

const {confirm} = Modal
const LOCAL_MEET = 'https://live-prod.truyenthanhso.vn/v2/stream/publish/?name='

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {pathname} = useLocation()
  const {currentUser, logout, auth} = useAuth()
  const VIEW_NOTIFICATION = currentUser?.Roles.find((item: Role) => {
    return item.Value === 45
  })
  const VIEW_CALL_SOS = currentUser?.Roles.find((item: Role) => {
    return item.Value === 75
  })
  const CONFIG_GROUP_BITRATE = currentUser?.Roles.find((item: Role) => {
    return item.Value === 15
  })
  const VIEW_STREAM_ADVANCED = currentUser?.Roles.find((item: Role) => {
    return item.Value === 79
  })
  const {query, refetch} = useQueryResponse()
  const queryClient = useQueryClient()

  const [showLoading, setShowLoading] = useState(false)
  // const [notification, setNotification] = useState({title: '', body: ''})
  const [isTokenFound, setTokenFound] = useState(false)
  const [isClickIconBell, setIsClickIconBell] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalSettingOpen, setIsModalSettingOpen] = useState(false)

  const [isMuted, setIsMuted] = useState<boolean>(false)
  const [volumeBars, setVolumeBars] = useState<number[]>(Array(NUM_BARS).fill(0))
  const [deadline, setDeadline] = useState<number>(0)
  const audioRef = useRef<HTMLVideoElement | null>(null)

  const [modal2Open, setModal2Open] = useState(false)
  const [isDisableMic, setIsDisableMic] = useState<boolean>(true)
  const [localStream, setLocalStream] = useState<MediaStream | null>(null)

  const [callId, setCallId] = useState('')

  const ref = useRef<any>()

  const localVideoRef = useRef<HTMLVideoElement>(null)
  const [peerConnection, setPeerConnection] = useState<RTCPeerConnection | null>(null)

  const audioContextRef = useRef<AudioContext | null>(null)
  const analyserRef = useRef<AnalyserNode | null>(null)
  const microphoneRef = useRef<MediaStreamAudioSourceNode | null>(null)

  const StopItem = useMutation((CallId: string) => stopStreaming(auth?.configs, CallId), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      Swal.fire({
        icon: 'success',
        title: 'Dừng thành công',
        text: ' ',
        showConfirmButton: false,
        timer: 1500,
      })
      if (peerConnection) {
        peerConnection.close()
        setPeerConnection(null)
      }
    },
    onError: () => {
      Swal.fire({
        icon: 'error',
        title: 'Dừng thất bại',
        text: ' ',
        confirmButtonText: 'Đóng lại',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (peerConnection) {
            peerConnection.close()
            setPeerConnection(null)
          }
        }
      })
    },
  })

  const pingMutation = useMutation({
    mutationFn: (payload: PingState) =>
      pingStreamState(auth?.configs, payload)
        .then()
        .catch((e: any) => {
          if (e.response.status === 666) {
            Swal.fire({
              icon: 'error',
              title: e.response.data,
              text: ' ',
              confirmButtonText: 'Đóng lại',
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                if (peerConnection) {
                  peerConnection.close()
                  setPeerConnection(null)
                }
              }
            })
          }
        }),
  })

  useEffect(() => {
    const initWebRTC = async () => {
      if (!audioRef.current) return

      // URL của stream
      const streamUrl = auth?.configs.rtcUrlWhip
        ? auth?.configs.rtcUrlWhip + callId
        : LOCAL_MEET + callId

      try {
        const peerConnection = new RTCPeerConnection({
          iceServers: [],
        })

        setPeerConnection(peerConnection)

        const stream = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: {
            channelCount: 1,
            echoCancellation: false, // Tắt chức năng loại bỏ tiếng vang
            autoGainControl: false, // Tắt chức năng giảm tiếng ồn
            noiseSuppression: false, // Tắt chức năng điều chỉnh âm lượng tự động
          },
        })
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = stream
        }
        // Bỏ đầu vào nghe
        audioRef.current.srcObject = stream

        stream.getTracks().forEach(async (track) => {
          if (track.kind === 'audio' && track.enabled) {
            peerConnection.addTrack(track, stream)
          }
        })

        stream.getAudioTracks().forEach((track) => {
          track.enabled = false // Toggle mute status
        })

        setLocalStream(stream)

        const audioSender = peerConnection.getSenders()
        audioSender.forEach((element) => {
          const audioParameters = element.getParameters()
          audioParameters.encodings[0].maxBitrate = 48000 // Set bitrate
          element
            .setParameters(audioParameters)
            .then(() => {})
            .catch((error) => {
              console.error('Error configuring audio bitrate:', error)
            })
        })

        // Gửi offer đến signaling server
        const offer = await peerConnection.createOffer()
        await peerConnection.setLocalDescription(offer)

        // Gửi offer đến signaling server
        await fetch(streamUrl, {
          method: 'POST',
          headers: {'Content-Type': 'application/sdp'},
          body: peerConnection.localDescription?.sdp,
        })
          .then((response: any) => {
            startListening(stream)
            return response.text()
          })
          .then((data: any) => {
            const sessionDescription = new RTCSessionDescription({
              type: 'answer',
              sdp: data,
            })

            peerConnection
              .setRemoteDescription(sessionDescription)
              .then(() => {
                peerConnection.onicecandidate = (event) => {
                  if (event.candidate) {
                    // Gửi ICE candidate đến server nếu cần
                  }
                }
                peerConnection.onconnectionstatechange = (event) => {}
              })
              .catch((error) => {
                console.error('Error setting remote description:', error)
              })

            // Clean up
            return () => {
              peerConnection.close()
            }
          })
      } catch (error) {
        console.error('Lỗi khi thiết lập WebRTC:', error)
      }
    }
    if (callId) {
      setDeadline(Date.now() + 15 * 1000)
      initWebRTC()
    }
  }, [callId])

  useEffect(() => {
    ref.current = setInterval(() => {
      callId && pingMutation.mutate({CallId: callId})
    }, 30000)

    return () => {
      if (ref.current) clearInterval(ref.current)
    }
  }, [callId])

  const startListening = async (stream: any) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({audio: true})
      const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)()
      const analyser = audioContext.createAnalyser()
      const microphone = audioContext.createMediaStreamSource(stream)

      microphone.connect(analyser)
      analyser.fftSize = 256
      const bufferLength = analyser.frequencyBinCount
      const dataArray = new Uint8Array(bufferLength)

      audioContextRef.current = audioContext
      analyserRef.current = analyser
      microphoneRef.current = microphone

      const updateVolume = () => {
        if (analyserRef.current) {
          analyserRef.current.getByteFrequencyData(dataArray)
          const bars = Array(NUM_BARS)
            .fill(0)
            .map((_, i) => {
              const start = (i * bufferLength) / NUM_BARS
              const end = ((i + 1) * bufferLength) / NUM_BARS
              const sum = dataArray.slice(start, end).reduce((a, b) => a + b, 0)
              return sum / (end - start)
            })
          setVolumeBars(bars)
        }
        requestAnimationFrame(updateVolume)
      }

      updateVolume()
    } catch (err) {
      console.error('Error accessing microphone: ', err)
    }
  }

  const oncloseModal2 = () => {
    endCall()
  }

  const endCall = () => {
    // Ngắt kết nối peerConnection nếu có
    FStop()
  }
  const FStop = () => {
    Swal.fire({
      title: `Bạn chắc chắn muốn dừng?`,
      text: ' ',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Xác nhận',
      cancelButtonText: 'Hủy bỏ',
      reverseButtons: true,
      confirmButtonColor: 'red',
      width: '500',
    }).then(async function (result) {
      if (result.value) {
        await StopItem.mutateAsync(callId)
        setCallId('')
        setModal2Open(false)
      }
    })
  }
  const showModal = () => {
    if (VIEW_STREAM_ADVANCED) {
      const callId = generateCallId()
      setModal2Open(true)
      setCallId(callId + '_' + currentUser?.UserName.replaceAll('.', '_'))
      callEmergency(auth?.configs, callId + '_' + currentUser?.UserName.replaceAll('.', '_')).catch(
        (error) => {
          handleCancel()
          Swal.fire({
            icon: 'error',
            title: error.response.data,
            text: ' ',
            confirmButtonText: 'Đóng lại',
          })
        }
      )
    } else {
      setIsModalOpen(true)
    }
  }

  const handleMuteToggle = () => {
    if (localStream) {
      localStream.getAudioTracks().forEach((track) => {
        track.enabled = !isMuted // Toggle mute status
      })
      setIsMuted((prev) => !prev) // Update state
    }
  }

  // Hàm để tính màu dựa trên mức âm lượng
  const getColorForVolume = (volume: number) => {
    const red = Math.min(228, Math.max(0, (volume / 228) * 228))
    const green = Math.min(228, 228 - (volume / 228) * 228)
    const blue = 249
    return `rgb(${red}, ${green}, ${blue})`
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const showModalSetting = () => {
    setIsModalSettingOpen(true)
  }

  const handleSettingCancel = () => {
    setIsModalSettingOpen(false)
  }
  // useEffect(() => {
  //   fetchToken(setTokenFound)
  // }, [])

  const confirmCall = () => {
    confirm({
      title: 'Gọi khẩn cấp',
      content: 'Bạn chắc chắn muốn gọi khẩn cấp không!',
      okText: 'Gọi',
      cancelText: 'Hủy',
      onOk: () => showModal(),
      bodyStyle: {backgroundColor: '#fff', borderRadius: '10px'},
    })
  }

  useEffect(() => {
    Modal.destroyAll()
  }, [pathname])

  // onMessageListener()
  //   .then((payload: any) => {
  //     setNotification({title: payload.notification.title, body: payload.notification.body})
  //     setShow(true)
  //   })
  //   .catch((err) => console.log('failed: ', err))

  // const onShowNotificationClicked = () => {
  //   setNotification({ title: "Notification", body: "This is a test notification" })
  //   setShow(true);
  // }

  let element: any = document.getElementById('biBell')
  if (element) {
    let styles = window.getComputedStyle(element, '::before')
    let content = styles.content
    if (content == '"ï†Š"') {
      setShowLoading(true)
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }

  const clickNotification = () => {
    if (VIEW_NOTIFICATION) setIsClickIconBell(true)
    else {
      Swal.fire({
        icon: 'warning',
        title: 'Bạn không có quyền xem thông báo!',
        text: ' ',
        confirmButtonText: 'Đóng lại',
      })
    }
  }

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Quick links */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <QuickLinks />
        {/* end::Menu wrapper */}
      </div>
      <input id='namek' type='hidden' />
      {/* ------------------------------ */}
      {/* TODO Tạm thời comment fix development */}
      {/* <Toast
        className='toast-notification'
        onClose={() => setShow(false)}
        show={show}
        delay={6000}
        autohide
        animation
      >
        <Toast.Header>
          <i className='bi bi-bell icon-bell-toast'></i>
          <h6 className='mr-auto'>{notification.title}</h6>
          <div className='flex-1'></div>
          <small>1 phút trước</small>
          <i title='Đóng' className='bi bi-x icon-x' onClick={() => setShow(false)}></i>
        </Toast.Header>
        <Toast.Body>{notification.body}</Toast.Body>
      </Toast> */}
      {/* <header className="App-header">
        {isTokenFound && <h1> Notification permission enabled 👍🏻 </h1>}
        {!isTokenFound && <h1> Need notification permission ❗️ </h1>}
        <button onClick={() => onShowNotificationClicked()}>Show Toast</button>
      </header> */}
      {/* ----------------------------------- */}
      {/* Call SOS */}
      <div className={clsx('d-flex align-items-center')}>
        {VIEW_CALL_SOS && (
          <button type='button' className='btn btn-primary' onClick={confirmCall}>
            <AudioOutlined />
            Phát khẩn cấp
          </button>
        )}
      </div>
      <div className={clsx('d-flex align-items-center')} style={{marginLeft: '5px'}}>
        {CONFIG_GROUP_BITRATE && (
          <button type='button' className='btn btn-primary' onClick={showModalSetting}>
            <SettingOutlined />
            Tùy chọn
          </button>
        )}
      </div>
      {/* NOTIFICATIONS */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Menu- wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          id='divBell'
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          title={isTokenFound ? 'Thông báo' : 'Bạn chưa cho phép trình duyệt thông báo'}
          onClick={clickNotification}
        >
          {/* <KTSVG
            path='/media/icons/duotune/general/gen022.svg'
            className={toolbarButtonIconSizeClass}
          /> */}
          <i id='biBell' className='bi bi-bell icon-bell'></i>
          <span
            className='bullet bullet-dot bg-success h-6px w-6px position-absolute animation-blink'
            style={{top: '18px'}}
          ></span>
        </div>
        <HeaderNotificationsMenu
          isClickIconBell={isClickIconBell}
          setIsClickIconBell={setIsClickIconBell}
        />

        {/* end::Menu wrapper */}
      </div>
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className='btn btn-icon btn-active-light-primary btn-custom position-relative'
          id='kt_drawer_chat_toggle'
        >
          <i className="bi bi-bell" style={{ fontSize: '21px' }}></i>
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div>
      </div> */}
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={toAbsoluteUrl(currentUser?.Url || '/media/avatars/avatar-default.png')} />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
      {/* begin::Aside Toggler */}
      {/* {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )} */}
      {showLoading && <Loading />}
      {/* Gọi khẩn cấp bằng jitsi */}
      {isModalOpen && <CallSOSModal handleCancel={handleCancel} isModalOpen={isModalOpen} />}
      {/* Gọi khẩn cấp bằng webrtc */}
      <Modal
        title='Thông báo khẩn câp'
        centered
        open={modal2Open}
        footer={
          <div className='text-center mt-5' key='action'>
            <button
              type='reset'
              className='btn btn-outline-primary me-3 cancel'
              data-kt-users-modal-action='cancel'
              onClick={() => {
                oncloseModal2()
              }}
            >
              Kết thúc thông báo
            </button>
          </div>
        }
      >
        <div>
          <div className='microphone-input'>
            <div className='volume-bars'>
              {isMuted &&
                volumeBars.map((volume, index) => (
                  <div
                    key={index}
                    className='volume-bar'
                    style={{height: `${volume}px`, backgroundColor: getColorForVolume(volume)}}
                  />
                ))}
            </div>
            {!isDisableMic && (
              <button
                title='Bật Mic'
                type='button'
                className='btn btn-mic'
                onClick={handleMuteToggle}
              >
                <i
                  style={{fontSize: 50, color: 'white'}}
                  className={isMuted ? 'bi bi-mic' : 'bi bi-mic-mute'}
                ></i>
              </button>
            )}
            {isDisableMic && (
              <Countdown
                className='btn-mic'
                title='Đến ngược thời gian bật mic'
                value={deadline}
                onFinish={() => setIsDisableMic(false)}
              />
            )}
          </div>
          <div>
            <audio
              style={{width: '100%', background: '#080213'}}
              ref={audioRef}
              autoPlay={true}
              muted
            />
          </div>
        </div>
      </Modal>
      {isModalSettingOpen && (
        <BitrateModal handleCancel={handleSettingCancel} isModalOpen={isModalSettingOpen} />
      )}
    </div>
  )
}

export {Topbar}
